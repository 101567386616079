import { MdApproval, MdOutlineEventSeat } from "react-icons/md";
import { CiClock2 } from "react-icons/ci";
import { BsCalendar2WeekFill } from "react-icons/bs";
import { FaRegFolder } from "react-icons/fa6";
import { MdManageHistory } from "react-icons/md";

export const ADMIN_TABS = [
  {
    id: 1,
    icon: <MdOutlineEventSeat color="#752938" size={20} />,
    title: "SEAT ALLOCATION",
    url: "#",
  },
];

export const MANAGER_TABS = [
  {
    id: 1,
    icon: <MdApproval color="#752938" size={20} />,
    title: "MANAGER APPROVALS",
    url: "#",
  },
];

export const LEARNER_TABS = [
  {
    id: 1,
    icon: <CiClock2 color="#752938" size={20} />,
    title: "AVAILABLE TRAININGS",
    url: "/learner/available-trainings",
  },
  {
    id: 2,
    icon: <BsCalendar2WeekFill color="#752938" size={20} />,
    title: "APPLICATION STATUS",
    url: "/learner/applied-trainings",
  },
  {
    id: 3,
    icon: <MdManageHistory color="#752938" size={20} />,
    title: "MY TRAINING HISTORY",
    // url: "/learner/my-trainings-history",
    url: "https://devorbicollege.orbico.com/legacy/lms/index.php%3Fr%3DmyActivities/index%26tab%3Dclassrooms",
  },
  // {
  //   id: 3,
  //   icon: <FaRegFolder color="#752938" size={20} />,
  //   title: "ADVANCED TRAININGS",
  //   url: "#",
  // },
];

export interface Tabs {
  id: number;
  icon: any;
  title: string;
  url: string;
}
