import { useSelector } from "react-redux";
import SpinnerLoader from "../../components/SpinnerLoader";
import { RootState } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { useEffect } from "react";

function HomePage() {
  const { message, loading, isAuthorized, userRoles } = useSelector(
    (state: RootState) => state.auth
  );

  const navigate = useNavigate();

  const getRolesItemsByUserRoles = () => {
    return UserRoleCards.filter((item) =>
      userRoles.includes(item.title.toLowerCase())
    );
  };
  useEffect(() => {
    sessionStorage.removeItem("role");
  }, []);

  if (!isAuthorized) {
    return (
      <div className="flex items-center w-full justify-center">
        {!loading && <p>{message}</p>}
        {loading && <SpinnerLoader />}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start gap-8">
      <Header />
      <div className="flex items-center w-full justify-center gap-5 h-[55vh]">
        {getRolesItemsByUserRoles().map((role) => (
          <div
            className="w-[350px] h-[250px] flex items-center justify-center rounded-xl shadow-lg cursor-pointer hover:shadow-xl transition-all duration-300"
            key={role.id}
            onClick={() => {
              navigate(role.path);
              sessionStorage.setItem("role", role.title);
            }}
          >
            <p className="text-lg font-semibold">
              {role.title === "Learner" && "My Trainings"}
              {role.title === "Manager" && "Manager Approvals"}
              {role.title === "Admin" && "My Admin Page"}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomePage;

const UserRoleCards = [
  {
    title: "Learner",
    path: "/learner/available-trainings",
    id: 1,
  },
  {
    title: "Manager",
    path: "/manager",
    id: 2,
  },
  {
    title: "HR",
    path: "/hr",
    id: 3,
  },
  {
    title: "Admin",
    path: "/courses",
    id: 4,
  },
];
